@import url('https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap');

* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;

  height: 100%;
  width: 100%;

  @media only screen and (max-width: $bp-large) {
    font-size: 50%;
  }
}

body {
  font-family: 'Muli', 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: $color-black;
  background-color: $color-background;
  min-height: 100%;
}

button {
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  color: inherit;
  font-size: inherit;

  &:active,
  &:focus {
    outline: none;
  }
}

h1 {
  font-family: 'Poppins', sans-serif;
}

p {
  font-size: $font-size-default;
}

table {
  thead {
    th {
      background: #909090;
      color: #FFFFFF;
    }
  }
  .total {
    td {
      background: #909090;
      font-weight:bold;
      color: #000000;
    }
  }
}

.align-center {
  text-align: center;
}

.buttons-wrapper {
  display: flex;
  margin-top: 20px;
  & > div{
    display: flex;
    flex-direction: column;
    &:first-child {
      margin-right: 10px;
    }
  }
}

.hidden {
  display: none;
}


@media print {
  button {
    display: none;
  }

  @page {
    size: 'A4';
    margin-top: 50px;
  }

  body {
    background-color: red;
    margin: 5mm;
    font-family: 'Poppins', 'Muli', 'Arial';
    font-size: 10px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 10px;
  }

  .table2 {
    border: none;
    width: 100%;
    font-size: 10px;
  }

  .table4 {
    margin-top : 50rem;
    border-collapse: collapse;
    width: 100%;
  }

  span.gbp {
    border: 1px inset #ccc;
  }

  table td input.test::before {
    text-align: left;
    content:"Rp";
    padding-bottom: 0 1px;
  }

  table td.amount::after {
    text-align: center;
    content:"..";
    color: transparent;
    padding-bottom: 0 1px;
    flex-direction: row;
  }

  table td.col_sm {
    text-align: left;
    content:"Rp";
    padding-bottom: 0 1px;
  }

  table td.amount::after {
    text-align: center;
    content:"..";
    color: transparent;
    padding-bottom: 0 1px;
    flex-direction: row;
  }

  table td.col_sm {
    text-align: center;
  }

  table th.test {
    text-align: center;

  table td.nominal {
    text-align: right;
    text-align: left;
  }

  table tr {
    page-break-inside: avoid !important;
    page-break-after: auto !important;
  }

  #logo {
    background-image:url("/img/logo-kopsurat-ricci.png");
    width: 5px;
    height: 5px;
    width: 18%;
    margin-left: -2;
  }

  #image {
    background-image:url("/img/logo-kopsurat-ricci.png");
    width: 5px;
    height: 5px;
    width: 18%;
    margin-left: -2;
  }

  h1 {
    font-size: 12px;
    line-height: 100%;
    margin: 0;
    font-weight: bold;
  }

  #print-container {
    width: 100%;
    & > thead#print-table-header{
      display: 'table-header-group';
      margin-bottom:5mm;
      tr th div {
        border: 3px double #000000;
      }
    }
    & > tfoot {
      display:table-footer-group;
    }

    & > thead#print-table-header2 > tr > th > div {
      padding: 8px;
    }

    & > tbody{
      div:first-child {
        margin-top:20px;
      }
      table {
        margin-top:20px;
        &#print-table-content {
          tr td table {
            thead {
              display: table-row-group !important;
            }
            td, th, div {
              border:1px solid #000000;
              padding: 1 5px;
            }
            tfoot {
              tr:first-child {
                td {
                  border-top: 5px double #000000;
                }
              }
              td {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  div[class*="header-top"] {
    display: flex;
    flex-direction: row;
  }

  div[class*="header-title"] {
    display: flex;
    flex-direction: column;
    flex: 8 1;
  }

  div[class*="top--left"] {
    display: flex;
    flex-direction: column;
    flex: 2 1;
  }

  div[class*="top--right"] {
    display: flex;
    flex-direction: column;
    flex: 2 1;
  }

  div[class*="footer-bottom"] {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 50%;
    bottom: 0;
  }

  div[class*="header2-head"] div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: bold;
  }

  div[class*="footer-bottom"] div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: bold;
  }

  div[class*="footer-bottom"] div p:first-child {
    margin-bottom: 20rem;
  }

  div[class*="header-title"] {
    clear: both;
  }

  #print-table-footer {
    display: table-footer-group;
  }

  #print-table-footer #page-counter{
    counter-increment: page;
    content: "Hal " counter(page) "/" counter(pages);
  }

  .page-break-avoid {
    page-break-inside: avoid !important;
    page-break-after: auto !important;
  }
}
}
